.projects-hero {
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: clamp(2.625rem, 7.222vw + 1rem, 7.5rem);
        text-align: center;
        text-transform: uppercase;
        line-height: 1;
        margin: 0;

        mark {
            display: block;
            background-color: transparent;
            padding: 0;
            color: transparent;
            -webkit-text-stroke: clamp(0.063rem, 0.185vw + 0.021rem, 0.188rem) $color-primary;
        }
    }

    &__background {
        position: absolute;
        background-color: $black;
        inset: 0;
        z-index: -1;
        mask-image: linear-gradient(to bottom, $black 60%, $black, rgba($black, 0));

        video,
        img {
            max-width: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.section {
    &.projects-about,
    &.projects-about-secondary {
        margin: 4rem 0 6rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
    }

    &.projects-gallery {
        display: none !important;   // TODO: remove
    }
}

.page-latest-projects {
    &.fiction {
        .projects-about-secondary {
            display: none !important;   // TODO: remove
        }
    }
}
