.page-about {
    padding-top: 16rem;
    padding-top: 10rem;
}

.section {
    &.about-intro {
        h1 {
            font-size: clamp(3.5rem, 3.148vw + 2.792rem, 5.625rem);
            font-size: clamp(2rem, 5.37vw + 0.792rem, 5.625rem);
            margin-bottom: 2rem;
        }
    }

    &.partners {
        margin-top: 4rem;

        h3 {
            font-size: 1.5rem;
            margin: 0 2rem 4rem 2rem;
        }
    }

    &.team {
        margin-top: 6rem;

        h2 {
            font-size: 2rem;
            margin-bottom: 2rem;
        }
    }

    &.join,
    &.sustainability,
    &.history {
        margin-top: 4rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
    }

    &.join {
        margin-top: 4rem;
    }
}

.partner-logo {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img,
    & > svg {
        display: block;
        max-width: 100%;
        max-height: 100%;
        user-select: none;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-gap: 3rem;
        row-gap: 5rem;
    }
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    flex: 0 1;
    flex-basis: 100%;
    padding: 1rem;

    strong {
        font-weight: $font-weight-700;
    }

    small {
        font-size: 0.75rem;
    }

    &__image {
        margin-bottom: 1rem;
    }

    &__list {
        list-style: none;
        margin: 4rem 0 0 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }
}

.history {
    &__intro {
        max-width: 30rem;
        margin: 0 auto;
    }
}

.timeline {
    list-style: none;
    padding: 0;
    margin: 4rem auto 8rem auto;
    max-width: 30rem;
}

.event {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    min-height: 6rem;

    &__year {
        flex: 0 0 4rem;
        text-align: right;
        font-size: clamp(1.5rem, 0.741vw + 1.333rem, 2rem);
        font-weight: $font-weight-700;
    }

    &__separator {
        padding-top: 0.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: stretch;
        gap: 0.5rem;

        &-item {
            &.dot {
                width: 1.125rem;
                height: 1.125rem;
                border: 1px solid;
                border-radius: 100%;
            }

            &.line {
                flex: 1 0;
                border-radius: 2px;
                width: 2px;
                background-color: currentColor;
            }
        }

        opacity: 0.25;
    }

    &__description {
        padding-top: 0.4rem;
        font-weight: $font-weight-300;
        line-height: 1.5;
        margin-bottom: 1rem;
    }

    &:last-of-type {
        min-height: unset;

        .event__description {
            margin-bottom: 0;
        }
    }
}

@media screen and (min-width: 576px) {
    .partner-logo {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .team-member {
        flex-basis: 50%;
    }
}

@media screen and (min-width: 768px) {
    .section {
        &.about-intro {
            h1 {
                margin-bottom: 4rem;
            }
        }
    }

    .team-member {
        &__list {
            margin-top: 6rem;
        }
    }

    .timeline {
        margin-top: 6rem;
    }

    .event {
        &__year {
            flex-basis: 6rem;
        }
    }
}

@media screen and (min-width: 992px) {
    .section {
        &.about-intro {
            h1 {
                margin-bottom: 8rem;
            }
        }

        &.partners,
        &.team {
            margin-top: 15rem;
        }

        &.join,
        &.sustainability,
        &.history {
            margin-top: 8rem;
        }
    }

    .partner-logo {
        &__list {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .team-member {
        flex-basis: 20%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .timeline {
        max-width: 48rem;
    }

    .event {
        gap: 10rem;

        &__description {
            margin-bottom: 4rem;
        }
    }
}
