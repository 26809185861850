.button {
    display: inline-flex;
    line-height: 1.5;
    font-size: 1rem;
    background-color: rgba($color-primary, 0.1);
    border: 1px solid $color-primary;
    color: $color-primary;
    text-decoration: none;
    border-radius: 10rem;
    padding: 1rem 3.5rem;
    transition:
        opacity $default-transition,
        background $default-transition,
        border $default-transition;

    &:hover {
        background-color: $highlight-3;
        border-color: $highlight-3;
    }

    &:active {
        background-color: $highlight-1;
        border-color: $highlight-1;
    }

    &:focus {
        border-color: $highlight-2;
        outline: 3px solid rgba($highlight-2, 0.5);
        outline-offset: 3px;
    }

    &--secondary {
        border-color: rgba($color-primary, 0);
    }

    &--submit {
        &.is-loading {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &::after {
                content: "";
                margin-left: 0.5em;
                flex: 0 0 auto;
                width: 1em;
                height: 1em;
                animation: spin 1s ease-in-out infinite;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z'/%3E%3Cpath d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466'/%3E%3C/svg%3E");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                opacity: 0.5;
            }
        }
    }

    &-sm {
        font-weight: 700;
        line-height: 1;
        padding: 0.5rem 0.75rem;
        font-size: 0.75rem;
        border-color: rgba($white, 0.25);
    }
}

button:disabled,
button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}
