/* COLORS */
$black: #000;
$white: #fff;
$bg-primary: #101010;
$color-primary: $white;
$color-footer: #666;

$highlight-1: #e337ff;
$highlight-2: #37abff;
$highlight-3: #7737ff;

/* FONTS */
$font-family-base: "Manrope", system-ui, sans-serif;
$font-family-title: "PP Monument Extended", system-ui, sans-serif;

$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;

/* TYPOGRAPHY */
$base-line-height: 1.4;
$header-line-height: 1.2;

/* ANIMATIONS */
$default-transition: 0.2s ease-in-out;
