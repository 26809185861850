.footer {
    color: $color-footer;
    padding: 9rem 0 4rem 0;
}

.footer a {
    color: inherit;
}

.footer a:hover {
    color: $color-primary;
}

.footer-logo {
    height: 2rem;
    margin-bottom: 1.5rem;
}

.footer-social {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
}

.footer-menu {
    &__items {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &__item {
        text-transform: uppercase;

        & + & {
            margin-top: 1rem;
        }
    }

    &__link {
        text-decoration: none;
    }
}

.footer-copyright {
    text-transform: uppercase;
    margin: 2rem 0 0 0;
    border-top: 1px solid;
    padding-top: 2rem;
}
