.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0.5rem 0;
    transition:
        background $default-transition,
        backdrop-filter $default-transition;

    &--has-background {
        background-color: rgba($bg-primary, 0.9);
        backdrop-filter: blur(0.5rem) contrast(150%);

        .sub-menu {
            background-color: rgba($bg-primary, 1);
        }
    }

    &--show-menu {
    }

    .row {
        align-items: center;
    }

    .site-logo {
        height: 2rem;

        &__link {
            display: block;
            color: $color-primary;

            &:hover {
                opacity: 0.75;
            }
        }
    }

    .hamburger-react {
        margin-left: auto;
    }

    .navigation {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        height: 100vh;
        height: 100dvh;
        background-color: $bg-primary;
        transform: translateX(100%);
        opacity: 0;
        padding: 4rem 0 0;
        transition:
            transform $default-transition,
            opacity $default-transition;

        &--show {
            opacity: 1;
            transform: translateX(0);
        }

        &__items {
            padding: 3rem 0;
            margin: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
        }

        &__item {
            margin-bottom: 0.75rem;
            text-transform: uppercase;
            font-size: 1.5rem;

            &--has-submenu {
                & > span {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;

                    svg {
                        transition:
                                transform $default-transition;
                    }
                }
            }

            &:hover > .navigation__link {
                &::after {
                    width: 100%;
                }
            }
        }

        &__link {
            display: block;
            width: 100%;
            position: relative;
            text-decoration: none;
            color: $color-primary;

            &::after {
                content: '';
                position: absolute;
                bottom: -0.25rem;
                left: 0;
                width: 0;
                height: 1px;
                background-color: currentColor;
                transition: width $default-transition;
            }
        }

        .sub-menu {
            margin: 0;
            padding: 0;
            list-style: none;
            margin-left: 3rem;
        }
    }
}

@media screen and (max-width: 767.98px) {
    .header {
        .navigation {
            &__item {
                &--has-submenu {
                    & > span {
                        svg {
                            &.rotated {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                    .sub-menu {
                        display: block;
                        height: auto;
                        max-height: 0;
                        overflow-y: hidden;
                        transition: 
                            max-height $default-transition;

                        &.sub-menu--show {
                            max-height: 100vh;
                        }

                        .navigation__item {
                            &:first-of-type {
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .header {
        padding: 2rem 0;

        .menu-toggle__wrapper {
            display: none;
        }

        .navigation {
            position: static;
            height: unset;
            background-color: transparent;
            transform: none;
            padding: 0;
            opacity: 1;

            &__items {
                padding: 0;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                gap: 4.375rem;
            }

            &__item {
                padding: 0.5rem 0;
                font-size: 0.75rem;
                margin-bottom: 0;

                &--has-submenu {
                    position: relative;

                    & > span {
                        svg {
                            width: 1rem;
                            height: 1rem; 
                        }
                    }

                    .sub-menu {
                        pointer-events: none;
                        position: absolute;
                        left: -1rem;
                        top: 100%;
                        display: block;
                        margin-left: 0;
                        width: 8rem;
                        opacity: 0;
                        padding: 1rem;
                        border-radius: 1rem;
                        transition: 
                            opacity $default-transition;
                    }

                    &:hover {
                        & > span {
                            svg {
                                transform: rotate(-180deg);
                            }
                        }

                        & > .sub-menu {
                            display: block;
                            pointer-events: all;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
