.page-not-found {
    h1 {
        font-size: clamp(3.5rem, 3.148vw + 2.792rem, 5.625rem);
        margin-bottom: 4rem;
    }

    .button-list {
        margin: 2rem 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: center;
        gap: 2rem;
        flex-wrap: wrap;
    }
}
