@font-face {
    font-display: swap;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 300;
    src: url("./../../fonts/manrope/manrope-v15-latin_latin-ext-300.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 400;
    src: url("./../../fonts/manrope/manrope-v15-latin_latin-ext-regular.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    src: url("./../../fonts/manrope/manrope-v15-latin_latin-ext-700.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "PP Monument Extended";
    font-style: normal;
    font-weight: 700;
    src: url("./../../fonts/pp-monument-extended/PPMonumentExtended-Bold.woff2") format("woff2");
}
