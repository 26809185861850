.page-contact {
    h1 {
        font-size: clamp(2.5rem, 1.852vw + 2.083rem, 3.75rem);
        margin-top: 0;
        margin-bottom: 4rem;
    }
}

.section {
    &.contact {
        margin-top: 10rem;
    }

    &.locations {
        margin-top: 4rem;
    }
}

.contact-form {
    &__wrapper {
        max-width: 36.25rem;
        margin: 0 auto;
    }

    .button {
        margin-top: 2rem;
    }
}

.form-group {
    position: relative;

    & + & {
        margin-top: 2rem;
    }

    label {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 1.15rem 2rem;
        position: absolute;
        left: 0;
        top: 0;
        transition:
            opacity $default-transition,
            font-size $default-transition,
            transform $default-transition;
    }
}

.form-control {
    color: $color-primary;
    line-height: 1;
    padding: 1.15rem 2rem;
    background: none;
    border-radius: 3.75rem;
    transition:
        padding $default-transition,
        border $default-transition;

    &::placeholder {
        color: transparent;
    }

    &:focus,
    &:active,
    &:hover {
        box-shadow: none;
        color: $color-primary;
        background: none;
    }

    &:active,
    &:focus {
        border-color: $highlight-1;
        box-shadow: 0 0 0 0.25rem rgba($highlight-1, 0.25);
    }

    &:focus,
    &:not(:placeholder-shown) {
        padding-bottom: 0.75rem;
        padding-top: 1.55rem;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ label {
        opacity: 0.5;
        font-size: 12px;
        transform: translateY(-0.65rem);
    }
}

textarea.form-control {
    border-radius: 20px;
    line-height: 1.5;
    min-height: 12rem;
    max-height: 20rem;
}

.map {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;

    &__wrapper {
        height: 35rem;
    }

    &__marker {
        display: block;
        background-image: url("./../../images/pin.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 14px;
        height: 15px;
    }
}

.office {
    text-align: center;

    &__list {
        margin: 4rem 0 0 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-gap: 4rem;
        padding: 0 1rem;
    }

    &__city {
        font-size: 2rem;
        margin: 0 0 2rem;
    }

    &__address {
        color: rgba($color-primary, 0.5);

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .office {
        &__list {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 2rem;
        }

        &__city {
            margin-bottom: 3rem;
        }
    }
}

@media screen and (min-width: 992px) {
    .page-contact {
        h1 {
            margin-bottom: 6rem;
        }
    }

    .section {
        &.contact {
            margin-top: 16rem;
        }
    }

    .office {
        &__list {
            grid-template-columns: repeat(3, 1fr);
            padding: 0 4rem;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.message {
    --bs-bg-opacity: 1;
    margin-top: 2rem;
    border-radius: 1rem;
    font-weight: 700;
    padding: 1rem 3rem;

    &.success {
        background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
    }

    &.error {
        background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
    }
}
