.facebook-box {
    width: 100%;
    border: 1px solid rgba($white, 0.25);

    &__image {
        display: block;
        aspect-ratio: 1;
        height: 100%;
        width: auto;
        max-height: 4rem;
    }

    &__title {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .facebook-box {
        width: unset;
        min-width: 18rem;
    }
}
