.page-projects {
    margin-top: 8rem;
}

@media screen and (min-width: 768px) {
    .page-projects {
        margin-top: 10rem;
    }
}

@media screen and (min-width: 992px) {
    .page-projects {
        margin-top: 14rem;
    }
}

.section {
    &.projects-tabs {
        .nav-tabs {
            max-width: 18rem;
            margin: 0 auto;
            gap: 1rem;
            border: none;
            justify-content: center;

            .nav-item {
                flex-basis: 100%;
            }

            .nav-link {
                width: 100%;
                font-size: 0.75rem;
                text-transform: uppercase;
                padding: 1.5rem 2rem;
                background: none;
                border: 1px solid transparent;
                border-radius: 10rem;
                color: rgba($color-primary, 0.5);

                &.active {
                    border-color: $color-primary;
                    color: $color-primary;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .section {
        &.projects-tabs {
            .nav-tabs {
                max-width: unset;

                .nav-item {
                    flex-basis: auto;
                }

                .nav-link {
                    padding: 1rem 2rem;
                }
            }
        }
    }
}

.project {
    $_overlay_color: $bg-primary;
    $_overlay_opacity: 0.3;
    $_overlay: rgba($_overlay_color, $_overlay_opacity);

    border-radius: 2rem;
    overflow: hidden;
    aspect-ratio: 320 / 180;
    background-color: $black;

    &__link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        color: $color-primary;
        text-decoration: none;
        position: relative;
        background-color: $black;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;

        &::before {
            pointer-events: none;
            content: "";
            position: absolute;
            z-index: 9;
            inset: -10px;
            background-color: $_overlay;
        }

        &--zoom-in {
            background-size: 150%;
        }

        &--active {
            background-image: none !important;
        }
    }

    &__background {
        pointer-events: none;
        position: absolute;
        inset: -5px;
        z-index: 0;

        .video,
        video,
        img {
            max-width: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
            // object-position: center;
        }

        &--zoom-in {
            .video,
            video,
            img {
                transform: scale(1.1);
            }
        }
    }

    &__list {
        list-style: none;
        margin: 4rem 0 0;
        padding: 0;
        display: grid;
        grid-gap: 2rem;
    }

    &__title {
        position: relative;
        z-index: 10;
        line-height: 1;
        margin: 0;
        font-size: 2rem;
        text-transform: uppercase;
        text-align: center;
        pointer-events: none;
    }
}

@media screen and (min-width: 768px) {
    .project {
        &__list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (min-width: 992px) {
    .project {
        &__list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.background-test {
    $_overlay_color: $bg-primary;
    $_overlay_opacity: 0.3;
    $_overlay: rgba($_overlay_color, $_overlay_opacity);

    .project {
        &__link {
            &::before {
                pointer-events: none;
                content: "";
                position: absolute;
                z-index: 9;
                inset: 0;
                background-color: $_overlay;
            }
        }
    }
}
