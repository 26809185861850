.hero-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.section {
    &.hero {
        height: 80vh;
        mask-image: linear-gradient(to bottom, rgba($black, 1) 60%, rgba($black, 1), rgba($black, 0));
    }

    &.about {
        position: relative;
        margin-top: -3rem;
        margin-bottom: 4rem;
        padding-bottom: 3rem;
        z-index: 5;

        h1 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
    }

    &.services {
        margin-top: 4rem;

        .row {
            margin-bottom: 4rem;
        }

        h2 {
            font-size: clamp(2rem, 0.741vw + 1.833rem, 2.5rem);
            margin-bottom: 2rem;
            text-align: center;
        }

        .services-image {
            width: 100%;
            height: 22.5rem;
            object-fit: cover;
            object-position: center;
            border-radius: 2rem;
            margin-bottom: 2rem;
        }

        .button {
            margin-top: 1rem;
        }
    }

    &.hypewriter {
        margin-top: 8rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }

        .button {
            margin-top: 1rem;
        }
    }
}

.usp-item {
    border: 1px solid;
    border-radius: 2rem;
    background-color: rgba($bg-primary, 0.05);
    min-height: 10.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    padding: 0 1rem;

    &__big,
    &__mid {
        margin: 0;
        text-transform: uppercase;
    }

    &__big {
        font-size: 3.75rem;
    }

    &__mid {
        font-size: 1.5rem;
    }

    > span {
        text-transform: uppercase;
        line-height: 1;
    }
}
@media screen and (min-width: 768px) {
    .section {
        &.about {
            h1 {
                margin-bottom: 0;
            }
        }

        &.services {
            h2 {
                margin-top: 1.5rem;
            }

            .services-image {
                aspect-ratio: 1;
                width: 100%;
                height: auto;
            }
        }
    }
}
@media screen and (min-width: 992px) {
    .section {
        &.about {
            margin-top: 6rem;
            padding-bottom: 0;

            h1 {
                margin-right: 2rem;
            }
        }

        &.services {
            h2 {
                margin-top: 4rem;
                text-align: left;
            }

            .services-image {
                aspect-ratio: 680 / 360;

                &__wrapper {
                    position: relative;
                    z-index: -1;

                    &--left {
                        margin-right: -8rem;
                    }

                    &--right {
                        margin-left: -8rem;
                    }
                }
            }
        }
    }
}
