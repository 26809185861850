body {
    font-size: 1rem;
    font-family: $font-family-base;
    font-weight: $font-weight-400;
    line-height: $base-line-height;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
    font-weight: $font-weight-700;
    font-family: $font-family-title;
}

h1 {
    line-height: $header-line-height;
}
