@media screen and (min-width: 768px) {
    .page-project {
        .project {
            &__list {
                grid-template-columns: repeat(3, 1fr);
            }

            &__title {
                font-size: 1rem;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .page-project {
        .project {
            &__title {
                font-size: 2rem;
            }
        }
    }
}

.section {
    &.project-hero {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            line-height: 1;
            font-size: clamp(2.5rem, 7.407vw + 0.833rem, 7.5rem);
            font-size: clamp(2rem, 8.148vw + 0.167rem, 7.5rem);
            text-align: center;
            text-transform: uppercase;
            margin: 0;
        }
    }

    &.project-about {
        margin-top: 4rem;

        h2 {
            font-size: 2.5rem;
            margin-bottom: 2rem;
        }
    }

    &.project-media {
        margin-top: 4rem;
    }

    &.related-projects {
        margin-top:  4rem;

        h2 {
            font-size: 2.5rem;
        }
    }

    &.further-projects {
        margin-top:  4rem;
        
        h2 {
            font-size: 2.5rem;
        }
    }
}

@media screen and (min-width: 768px) {
    .section {
        &.project-media {
            margin-top: 8rem;
        }
    }
}

.project-hero {
    position: relative;

    &__background {
        position: absolute;
        background-color: $black;
        inset: 0;
        z-index: -1;
        mask-image: linear-gradient(to bottom, $black 60%, $black, rgba($black, 0));

        .video,
        video, 
        img {
            max-width: unset;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.project-details {
    margin: 2rem;
    padding: 0;
    list-style: none;

    &__item {
        display: flex;
        align-items: baseline;
        gap: 1rem;
        margin-bottom: 0.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        span:first-of-type {
            flex: 0 0 50%;
            color: rgba($color-primary, 0.5);
        }

        span:last-of-type {
            font-weight: $font-weight-700;
            text-transform: uppercase;
        }
    }
}

@media screen and (min-width: 768px) {
    .project-details {
        margin-top: 0;
        margin-bottom: 0;
    }
}

@media screen and (min-width: 992px) {
    .project-details {
        margin-left: 4rem;
        margin-right: 4rem;

        &__item {
            span:first-of-type {
                flex-basis: 10rem;
            }
        }
    }
}

.project-video, 
.project-gallery {
    max-width: 61.25rem;
    margin: auto;
}

.project-video {
    aspect-ratio: 16 / 9;
    border-radius: 2rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    background-color: $black;

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.project-gallery {
    display: grid;
    grid-gap: 1.5rem;

    display: none !important;   // TODO: remove

    span {
        background-color: $black;
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
        border-radius: 2rem;
        overflow: hidden;

        &:first-of-type {
            aspect-ratio: 8 / 9;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: unset;
        }
    }
}

@media screen and (min-width: 768px) {
    .project-gallery {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, auto);

        span {
            &:nth-of-type(1) {
                aspect-ratio: unset;
                grid-column: 1 / 2;
                grid-row: 1 / 3;
            }

            &:nth-of-type(2),
            &:nth-of-type(3) {
                grid-column: 2 / 3;
            }

            &:nth-of-type(2) {
                grid-row: 1 / 2;
            }

            &:nth-of-type(3) {
                grid-row: 2 / 3;
            }

            &:nth-of-type(4) {
                grid-column: 1 / 3;
                grid-row: 3 / 4;
            }
        }
    }
}

.page-project {
    &.the-ambassador-ambassadoren {
        h1 {
            font-size: clamp(1.5rem, 7.037vw - 0.083rem, 6.25rem);
        }
    }
}
