html,
body {
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

body {
    overflow-x: clip;
    overflow-y: visible; 
    min-height: 100%;
    background-color: $bg-primary;
    color: $color-primary;
    background-image: url("./../../images/background-grid.svg");
    background-position: center;
    background-repeat: repeat;
    position: relative;
}

::selection {
    background: rgba($highlight-1, 0.25);
    color: $color-primary;
}

a {
    color: inherit;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

@media screen and (min-width: 768px) {
    .ellipse {
        pointer-events: none;
        position: absolute;
        display: block;
        opacity: 0.6;
        mix-blend-mode: screen;
        aspect-ratio: 1;
        z-index: -1;

        &--1 {
            background: radial-gradient(50% 50% at 50% 50%, rgba($highlight-1, 0.6) 18%, rgba($highlight-1, 0) 100%);
            width: 600px;
            top: -400px;
            right: -300px;
        }

        &--2 {
            background: radial-gradient(50% 50% at 50% 50%, rgba($highlight-2, 0.6) 0%, rgba($highlight-2, 0) 100%);

        }
    }
}

@media screen and (min-width: 992px) {
    .ellipse {
        &--1 {
            width: 1386px;
            top: -878px;
            right: -693px;
        }

        &--2 {
            width: 1018px;
            top: 274px;
            left: -498px;
        }
    }
}
